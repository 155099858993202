// header menu
// commented out promotion menu until we activate in next phase [MAR.02.2022]
export const headerEngMenu = [
  { name: "Home", path: "/" },
  { name: "Feature", path: "/feature" },
  { name: "Merchant", path: "/merchant" },
  { name: "Career", path: "/career" },
  { name: "FAQ", path: "/faq" },
];

// header menu
export const headerMyMenu = [
  { name: "Halaman Utama ", path: "/" },
  { name: "Ciri", path: "/feature" },
  { name: "Peniaga", path: "/merchant" },
  { name: "Kerjaya", path: "/career" },
  { name: "Soalan Lazim", path: "/faq" },
];

export const downloadEng = "Download";
export const downloadMy = "Muat Turun";

// career link
export const careerMyLink = "https://careers.shopee.com.my";

// FAQ link
export const faqMyLink =
  "https://help.shopee.com.my/my/s/global-search/shopeepay";

// language toggle value string since local storage value does not take boolean value

export const TRUE = "TRUE";
export const FALSE = "FALSE";
