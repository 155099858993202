// external modules
import { React, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// internal modules
import { setCurrentUrl } from "src/RootRedux/UrlRedux/Actions";
import { isDesktop } from "isDesktop";
import LanguageToggle from "src/Common/Components/LanguageToggle";
import { Text, Menu } from "src/Common/Theme/Typography";
import {
  headerEngMenu,
  headerMyMenu,
  careerMyLink,
  faqMyLink,
  downloadEng,
  downloadMy,
} from "./Constants";
import { REGIONS } from "src/Services/RegionGetter/Constants";
import { regionGetter } from "src/Services/RegionGetter";
import { setRegion } from "src/Common/Redux/Actions";
import shopeePayLogo from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogo2022.svg";
import shopeePayLogoMobile from "src/Common/Assets/Icons/ShopeeLogo/shopeePayLogoMobile2022.svg";
import "./style.less";

export const AppHeader = () => {
  const dispatch = useDispatch();
  const region = regionGetter();

  // hooks for checking pathname for header menu color change
  const location = useLocation();
  const { pathname } = location;

  // reducer
  const regionLanguage = useSelector((state) => state.RegionLanguageReducer);
  const { language } = regionLanguage;

  const currentUrlPageReducer = useSelector((state) => state.currentUrlReducer);
  const { currentUrl } = currentUrlPageReducer;

  // offCanvas for mobile
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    isOpen === false ? setIsOpen(true) : setIsOpen(false);
  };

  // state for clickedMenu and header contents
  const [headerMenu, setHeaderMenu] = useState([]);
  const [download, setDownload] = useState("");

  // when app first renders, it sets the language
  const setRegionOnMount = useCallback(() => {
    dispatch(setRegion(region));
  }, [dispatch, region]);

  useEffect(() => {
    setRegionOnMount();
  }, [setRegionOnMount]);

  // useeffect for menu color change
  useEffect(() => {
    dispatch(setCurrentUrl(pathname));
  }, [location]);

  useEffect(() => {
    switch (true) {
      case language === REGIONS.malaysia:
        setHeaderMenu(headerMyMenu);
        setDownload(downloadMy);
        break;
      case region === REGIONS.malaysia && language === "en":
        setHeaderMenu(headerEngMenu);
        setDownload(downloadEng);
        break;
      default:
    }
  }, [language, region]);

  const redirectToNewPage = (path) => {
    if (path.includes("faq")) {
      window.open(faqMyLink);
    } else if (path.includes("career")) {
      window.open(careerMyLink);
    }
  };

  // for color change of header
  const onButtonClick = (path) => {
    setIsOpen(false);
    window.scrollTo(0, 0);
    dispatch(setCurrentUrl(path));
  };

  return (
    <>
      {isDesktop() ? (
        <div className="AppHeader">
          <div className="AppHeader__Contents">
            <Link to="/" onClick={() => onButtonClick("/")}>
              <img
                src={shopeePayLogo}
                className="AppHeader__Contents__shopeeLogo"
                alt="ShopeeLogo"
              />
            </Link>
            <nav>
              {headerMenu.map((menu) => {
                const { name, path } = menu;

                const isCareerOrFAQ =
                  path.includes("faq") || path.includes("career");

                return isCareerOrFAQ ? (
                  <span
                    name={path}
                    key={path}
                    className={
                      currentUrl === path
                        ? "AppHeader__Contents__styledLink__Clicked"
                        : "AppHeader__Contents__styledLink"
                    }
                    onClick={() => redirectToNewPage(path)}
                  >
                    <Menu>{name}</Menu>
                  </span>
                ) : (
                  <Link
                    key={path}
                    className={
                      currentUrl === path
                        ? "AppHeader__Contents__styledLink__Clicked"
                        : "AppHeader__Contents__styledLink"
                    }
                    to={path}
                    name={name}
                    onClick={() => onButtonClick(path)}
                  >
                    <Menu>{name}</Menu>
                  </Link>
                );
              })}
              <Link to={"/download"} onClick={() => onButtonClick("/download")}>
                <button className="AppHeader__Contents__headerButton">
                  <Text
                    roboto
                    regular
                    className="AppHeader__Contents__headerButton__Text"
                  >
                    {download}
                  </Text>
                </button>
              </Link>
              {currentUrl !== "/terms" && (
                <span className={`AppHeader__Contents__LanguageToggle`}>
                  <LanguageToggle />
                </span>
              )}
            </nav>
          </div>
        </div>
      ) : (
        <div className="AppHeaderMobile">
          <Link to="/" onClick={() => onButtonClick("/")}>
            <img
              src={shopeePayLogoMobile}
              className="AppHeaderMobile__shopeeLogo"
              alt="ShopeeLogo"
            />
          </Link>
          <div>
            <input
              type="checkbox"
              className="openSidebarMenu"
              id="openSidebarMenu"
              checked={isOpen}
              onChange={handleOpen}
            />
            <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
              <div className="spinner diagonal part-1" />
              <div className="spinner horizontal" />
              <div className="spinner diagonal part-2" />
            </label>

            {isOpen && (
              <div
                className="AppHeaderMobile__menuBackground"
                onClick={handleOpen}
              />
            )}
            <div id="sidebarMenu">
              <div className="sidebarMenuInner">
                {headerMenu.map((menu) => {
                  const { name, path } = menu;
                  const isCareerOrFAQ =
                    path.includes("faq") || path.includes("career");
                  return isCareerOrFAQ ? (
                    <a
                      href={path.includes("faq") ? faqMyLink : careerMyLink}
                      className="AppHeaderMobile__itemNav"
                      key={path}
                      name={path}
                    >
                      <li
                        key={path}
                        name={path}
                        className={
                          currentUrl === path
                            ? "AppHeaderMobile__styledLink__Clicked"
                            : "AppHeaderMobile__styledLink"
                        }
                      >
                        {name}
                      </li>
                    </a>
                  ) : (
                    <Link
                      to={path}
                      key={path}
                      name={path}
                      onClick={() => onButtonClick(path)}
                    >
                      <li
                        key={path}
                        name={path}
                        className={
                          currentUrl === path
                            ? "AppHeaderMobile__styledLink__Clicked"
                            : "AppHeaderMobile__styledLink"
                        }
                      >
                        {name}
                      </li>
                    </Link>
                  );
                })}
              </div>
              <div
                style={{
                  display: "block",
                  margin: "auto",
                  width: "80%",
                  borderTop: "1px solid rgba(0, 0, 0, 0.09)",
                  color: "#000000",
                }}
              />
              <div className={`AppHeaderMobile__LanguageToggle`}>
                <LanguageToggle />
              </div>
              <a href="/download">
                <div className="AppHeaderMobile__downloadBtn">
                  <Text
                    roboto
                    medium
                    className="AppHeaderMobile__downloadBtn__Text"
                  >
                    {download}
                  </Text>
                </div>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
