// external modules
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// internal modules
import { FALSE, TRUE } from "../AppHeader/Constants";
import { Text } from "src/Common/Theme/Typography";
import globeIcon from "./Assets/globeIcon.svg";
import { regionGetter } from "src/Services/RegionGetter";
import { setLanguage } from "src/Common/Redux/Actions";
import "./style.less";

const LanguageToggle = () => {
  const region = regionGetter();
  const capitalizedRegion = region.toUpperCase();
  const dispatch = useDispatch();

  // state
  const [isEnglish, setIsEnglish] = useState(false);

  // checking if Eng is saved as true
  const isEngSaved = localStorage.getItem("Eng") === TRUE;

  // to hide language toggle for terms and condition
  const location = useLocation();

  // useEffect
  useEffect(() => {
    if (isEngSaved) {
      setIsEnglish(true);
      dispatch(setLanguage("en"));
    } else {
      setIsEnglish(false);
      dispatch(setLanguage(region));
    }
  }, [isEngSaved]);

  // useEffect(() => {}, []);

  const toggleChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      localStorage.setItem("Eng", TRUE);
      dispatch(setLanguage("en"));

      setIsEnglish(true);
    } else if (!checked) {
      localStorage.setItem("Eng", FALSE);
      dispatch(setLanguage(region));

      setIsEnglish(false);
    }
  };

  // terms should not show language toggle button
  if (location.pathname === "/terms") {
    return null;
  }
  return (
    <div className={"LanguageToggle"}>
      <div className={`LanguageToggle__Contents`}>
        <img src={globeIcon} alt="globeIcon" />
        <Text roboto regular>
          {capitalizedRegion}
        </Text>
        <input type="checkbox" onChange={toggleChange} checked={isEnglish} />
        <div className={`LanguageToggle__Knobs__${capitalizedRegion}`} />
        <Text roboto regular>
          EN
        </Text>
      </div>
    </div>
  );
};

export default LanguageToggle;
